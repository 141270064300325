<template>
  <TooltipPortal>
    <TooltipContent
      v-bind="{ ...forwarded, ...$attrs }"
      :class="[variants({ variant }), props.class]"
      style="z-index: 6001"
    >
      <slot />
    </TooltipContent>
  </TooltipPortal>
</template>

<script setup lang="ts">
import {
  TooltipContent,
  type TooltipContentEmits,
  type TooltipContentProps,
  TooltipPortal,
  useForwardPropsEmits,
} from "radix-vue";
import { computed, type HTMLAttributes } from "vue";
import {
  cva,
  type VariantProps as _VariantProps,
} from "class-variance-authority";

defineOptions({
  inheritAttrs: false,
});

const variants = cva(
  `z-50 overflow-hidden animate-in
  fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95
  data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2
  data-[side=top]:slide-in-from-bottom-2 rounded-md px-3 py-1.5 text-xs text-primary-foreground
  border border-weak
  `,
  {
    variants: {
      variant: {
        default: "bg-main text-main",
        primary: "bg-primary text-main",
        positive: "bg-positive text-main",
        flat: "bg-transparent text-main",
        negative: "bg-negative text-main",
        secondary: "bg-secondary text-main",
      },
    },
  }
);
export type VariantProps = _VariantProps<typeof variants>;

const props = withDefaults(
  defineProps<
    TooltipContentProps & {
      class?: HTMLAttributes["class"];
      variant?: VariantProps["variant"];
    }
  >(),
  {
    sideOffset: 4,
    class: undefined,
    variant: "default",
    side: "bottom",
  }
);

const emits = defineEmits<TooltipContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>
